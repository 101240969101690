import React, { useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import nl from 'date-fns/locale/nl';

import { calculateTimeLeft } from '../utils/counter';
import { ICounter } from '../models/counter';
import { IMatchRoundData } from '../models/matchRoundData';
import { parse } from 'date-fns';

interface IProps {
	matchRoundsData: IMatchRoundData;
}

let intervalId: NodeJS.Timer | undefined = undefined;

export default function Countdown({ matchRoundsData }: IProps) {
	const [remainingTime, setRemainingTime] = useState<ICounter | null>(null);

	const targetDateTime = useMemo(() => new Date(`${matchRoundsData.start_date}T19:30:00`), [matchRoundsData]);

	const dateString = useMemo(() => format(targetDateTime, 'EEEE dd MMMM HH:mm', { locale: nl }), [targetDateTime]);

	const dayString = useMemo(() => {
		const dateTime = parse(dateString, 'EEEE dd MMMM HH:mm', new Date(), {
			locale: nl,
		});
		return format(dateTime, 'EEEE', { locale: nl });
	}, [dateString]);

	useEffect(() => {
		startTimer();
		return () => stopTimer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetDateTime]);

	const startTimer = () => {
		stopTimer();
		intervalId = setInterval(initInterval, 1000);
		initInterval();
	};

	const stopTimer = () => {
		if (intervalId !== undefined) {
			const tmpIntervalId: NodeJS.Timer = intervalId;
			intervalId = undefined;
			clearInterval(tmpIntervalId as NodeJS.Timeout);
		}
	};

	const initInterval = () => {
		const updatedTime = calculateTimeLeft(targetDateTime);
		setRemainingTime(updatedTime);

		if (updatedTime === null) {
			stopTimer();
		}
	};

	return (
		<div className="dynamic-match-schedule bg-kkd-dark">
			<div className="espn-wrapper">
				<div className="espn-logo">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 554 137">
						<path
							fill="#E2013D"
							d="M181.064.348c-20.608-.027-34.256 10.836-36.176 27.079a1600.065 1600.065 0 0 1-1.384 11.257H411.64s.504-3.957.896-7.133C414.552 15.188 407.6.35 382.312.35v.002S191.928.36 181.064.348zM17.424.353l-4.706 38.331h121.6l4.688-38.33H17.422h.002zm408.184 0l-4.696 38.331h131.824s.16-1.386.744-5.898C556.688 7.626 540.456.353 524.784.353h-99.176zm-6.512 52.926l-10.272 83.656 45.48-.016 10.28-83.624-45.488-.018v.002zm86.4 0l-10.288 83.656 45.48-.016 10.28-83.624-45.472-.018v.002zm-494.552.012L.654 136.939h121.592l4.48-36.288-76.138-.008 1.926-15.648h76.108l3.896-31.702H10.95l-.006-.002zm130.776 0c-3.336 21.832 7.592 31.701 23.08 31.701 8.424 0 61.52-.024 61.52-.024l-1.92 15.672-88.488.008-4.456 36.288s96.336.032 100.24 0c3.224-.232 25.76-.848 33.432-19.28 2.488-5.984 4.688-27.44 5.304-31.944 3.544-26.16-14.568-32.397-28.832-32.397-7.864 0-84.352-.024-99.88-.024zm141.552 0L273 136.939h45.456l6.4-51.944h57.096c16.192 0 24.896-8.706 26.512-20.397a430.97 430.97 0 0 0 1.4-11.305H283.272v-.002z"
						></path>
					</svg>
				</div>
				{remainingTime !== null && (
					<div className="mt-8 theinhardt">
						<p className="uppercase mb-1 font-bold">{dateString}</p>
						<div className="text-2xl uppercase mb-0 font-bold">{`Voetbal op ${dayString}`}</div>
					</div>
				)}
			</div>

			{remainingTime !== null && (
				<div id="espn-countdown" className="espn-countdown font-mono" data-countdown="">
					<div className="w-auto mx-auto">
						<div className="flex space-x-2 text-center">
							<div className="days">
								<div className="number">{String(remainingTime.days).padStart(2, '0')}</div>
								<small className="font-inter text-red-500">
									{remainingTime.days === 1 ? 'Dag' : 'Dagen'}
								</small>
							</div>
							<div className="hours">
								<div className="number">{String(remainingTime.hours).padStart(2, '0')}</div>
								<small className="font-inter text-red-500">Uur</small>
							</div>
							<div className="minutes">
								<div className="number">{String(remainingTime.minutes).padStart(2, '0')}</div>
								<small className="font-inter text-red-500">Min</small>
							</div>
							<div className="seconds">
								<div className="number">{String(remainingTime.seconds).padStart(2, '0')}</div>
								<small className="font-inter text-red-500">Sec</small>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
