import { ICounter } from '../models/counter';

export const calculateTimeLeft = (targetDateTime: Date) => {
	const now = new Date().getTime();
	const targetTime = targetDateTime.getTime();
	const timeDifference = targetTime - now;

	if (timeDifference < 0) {
		return null;
	}

	const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor(
		(timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
	);
	const minutes = Math.floor(
		(timeDifference % (1000 * 60 * 60)) / (1000 * 60),
	);
	const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

	return {
		days,
		hours,
		minutes,
		seconds,
	} as ICounter;
};
